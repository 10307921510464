<template>
  <el-container>
    <el-main>
      <login-bar />
      <router-view />
      <el-row class="movie-list">
        <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px" />
        <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px" />
        <el-col :md="8" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
          <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px; text-align: center">
            <el-card class="box-card">
              <el-tabs v-model="activeName" @tab-click="tabClick">
                <el-tab-pane name="login1">
                  <span slot="label">密码登录</span>
                  <div class="text item">
                    <el-form ref="form" :model="userLogin" label-width="80px">
                      <el-form-item label="帐号">
                        <el-input
                          v-model="userLogin.principal"
                          placeholder="请输入手机号或邮箱"
                          style="padding-right: 1px"
                          clearable
                        />
                      </el-form-item>
                      <el-form-item label="密码">
                        <el-input
                          v-model="userLogin.credential"
                          type="password"
                          placeholder="请输入密码"
                          style="padding-right: 1px"
                          clearable
                        />
                      </el-form-item>
                      <el-form-item label="图形验证码" label-width="90px">
                        <el-image :src="captchaCode" @click="getCaptcha" />
                        <el-input
                          v-model="userLogin.captchaCode"
                          placeholder="请输入图形验证码"
                          style="width: 50%; padding-right: 1px"
                          clearable
                        />
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" size="mini" :loading="isLoading" @click.native="loginBtn">登 录</el-button>
                        <el-button type="plain" size="mini" @click="register">注册帐号</el-button>
                        <el-button type="plain" size="mini" @click="forgot">忘记密码</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-tab-pane>
                <el-tab-pane name="login2">
                  <span slot="label">验证码登录</span>
                  <div class="text item">
                    <el-form ref="form" :model="userLogin" label-width="80px">
                      <el-input v-model="userLogin.loginType" type="hidden" value="1" />
                      <el-form-item label="帐号">
                        <el-input
                          v-model="userLogin.principal"
                          placeholder="请输入手机号或邮箱"
                          style="padding-right: 1px"
                          clearable
                        />
                        <el-button :disabled="isBtn" @click="loginVerifyCode">{{ code }}</el-button>
                      </el-form-item>
                      <el-form-item label="验证码">
                        <el-input
                          v-model="userLogin.credential"
                          placeholder="请输入验证码"
                          style="padding-right: 1px"
                          clearable
                        />
                      </el-form-item>
                      <el-form-item label="图形验证码" label-width="90px">
                        <el-image :src="captchaCode" @click="getCaptcha" />
                        <el-input
                          v-model="userLogin.captchaCode"
                          placeholder="请输入图形验证码"
                          style="width: 50%; padding-right: 1px"
                          clearable
                        />
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" size="mini" :loading="isLoading" @click.native="loginByVerifyCode">登 录</el-button>
                        <el-button type="plain" size="mini" @click="register">注册帐号</el-button>
                        <el-button type="plain" size="mini" @click="forgot">忘记密码</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-card>
          </el-row>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import { userMixin } from 'assets/js/mixin'
import LoginBar from 'components/layout/LoginBar'

export default {
  name: 'Login',
  components: {
    LoginBar
  },
  mixins: [userMixin],
  data() {
    return {
      activeName: 'login1'
    }
  },
  watch: {
    $route() {
      this.$router.go()
    }
  },
  created() {
    document.title = '用户登录'
    this.fetchPubkey(1)
  },
  methods: {
    tabClick(tab) {
      this.activeName = tab.name
    },
    register() {
      this.$router.push('/register')
    },
    forgot() {
      this.$router.push('/forgot')
    }
  }
}
</script>

<style>
/*处于手机屏幕时*/
@media screen and (max-width: 768px){
  .movie-list {
    padding-top: 8px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

.movie-list {
  padding-top: 15px;
  padding-left: 6%;
  padding-right: 6%;
}
</style>
